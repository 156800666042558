import { hooks } from '@/connectors/metamask'
import { formatUnits } from '@ethersproject/units'
import WalletButton from './components/WalletButton'
import Portal from './components/Portal'
import Progress from './components/Progress'
import Claim from './components/Claim'
import { useCanClaimAmount, useSignature, useInfoView, useStep } from './hooks/airdrop'

const { useAccount } = hooks

function AirDrop() {
  const account = useAccount()
  const canClaimAmount = useCanClaimAmount()
  const infoView = useInfoView()
  const signature = useSignature()
  const step = useStep()

  return (
    <>
      <Portal id="wallet">
        <WalletButton />
      </Portal>
      <Portal id="amount">
        <span>{signature && canClaimAmount && !infoView?.claimed ? formatUnits(canClaimAmount, 18) : '0'} PEPERA</span>
      </Portal>
      <Progress value={infoView ? infoView.claimedSupply.mul(100).div(infoView.initClaim).toNumber() : 0} />
      <Portal id="claim">
        <Claim 
          signature={signature}
          canClaimAmount={canClaimAmount}
          isClaimed={infoView?.claimed ?? false}
          step={step}
          inviteUsers={infoView && infoView.inviteUsers}
        />
      </Portal>
      <Portal id="referrer">
        <span>{infoView ? infoView.inviteUsers.toNumber() : 0}</span>
      </Portal>
      <Portal id="share">
        <a 
          href={`https://twitter.com/intent/tweet?url=https://airdrop.pepera.io${account ? `?r=${account}` : ''}&text=Cheers for $PEPERA airdrop! Join me at `} 
          target="_blank" 
          className="claim-button hoverable"
          rel="noreferrer"
        >
          <div>Share</div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21">
              <path
                d="M 22.82 10.47 L 15.32 2.97 C 15.215 2.865 15.081 2.794 14.936 2.765 C 14.79 2.736 14.639 2.751 14.502 2.807 C 14.365 2.864 14.248 2.96 14.166 3.083 C 14.083 3.207 14.039 3.352 14.039 3.5 L 14.039 7.283 C 11.607 7.491 8.921 8.682 6.712 10.556 C 4.051 12.813 2.394 15.722 2.047 18.747 C 2.019 18.982 2.067 19.219 2.183 19.426 C 2.298 19.632 2.476 19.797 2.691 19.896 C 2.906 19.996 3.147 20.025 3.379 19.98 C 3.611 19.935 3.823 19.817 3.985 19.645 C 5.017 18.547 8.686 15.075 14.039 14.77 L 14.039 18.5 C 14.039 18.648 14.083 18.793 14.166 18.917 C 14.248 19.04 14.365 19.136 14.502 19.193 C 14.639 19.249 14.79 19.264 14.936 19.235 C 15.081 19.206 15.215 19.135 15.32 19.03 L 22.82 11.53 C 22.96 11.389 23.039 11.199 23.039 11 C 23.039 10.801 22.96 10.611 22.82 10.47 Z M 15.539 16.69 L 15.539 14 C 15.539 13.801 15.46 13.61 15.319 13.47 C 15.179 13.329 14.988 13.25 14.789 13.25 C 12.156 13.25 9.592 13.937 7.168 15.294 C 5.933 15.988 4.783 16.822 3.74 17.78 C 4.283 15.545 5.654 13.42 7.682 11.699 C 9.859 9.853 12.516 8.75 14.789 8.75 C 14.988 8.75 15.179 8.671 15.319 8.53 C 15.46 8.39 15.539 8.199 15.539 8 L 15.539 5.311 L 21.229 11 Z"
                fill="#264a20"
              ></path>
            </svg>
          </div>
        </a>
      </Portal>
    </>
  )
}

export default AirDrop
