import { BrowserRouter } from 'react-router-dom';
import Web3Provider from './components/Web3Provider';
import BlockNumber from './components/BlockNumber';
import AirDrop from './airdrop';

function Root() {
  return (
    <BrowserRouter>
      <Web3Provider>
        <BlockNumber>
          <AirDrop />
        </BlockNumber>
      </Web3Provider>
    </BrowserRouter>
  );
}

export default Root;
