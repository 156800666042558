import { useState, useMemo, useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { useClaim } from '@/hooks/airdrop'

interface Props {
  signature: string | null
  canClaimAmount: BigNumber | null
  isClaimed: boolean
  step: number
  inviteUsers: BigNumber | null
}

function Claim({ signature, canClaimAmount, isClaimed, inviteUsers, step }: Props) {
  const [loading, setLoading] = useState(false)
  const disabled = useMemo(() => {
    return loading || !signature || canClaimAmount?.lte(0) || isClaimed || step === 0 || (step === 4 && inviteUsers?.eq(0))
  }, [signature, loading, isClaimed, canClaimAmount, step, inviteUsers])
  const claim = useClaim()
  const handleClick = useCallback(async () => {
    if (!disabled && signature) {
      setLoading(true)
      try {
        await claim(signature as string)
      } catch (err) {
        console.log(err)
        window.alert('Transaction failed!')
      }
      setLoading(false)
    }
  }, [disabled, claim, signature])


  return (
    <button 
      className={`claim-button hoverable ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {
        loading 
          ? 'Loading...' 
          : step === 0 ? 'Coming Soon'
          : step > 4 || (step === 4 && inviteUsers?.eq(0)) ? 'Airdrop has ended'
          : isClaimed ? 'Claimed'
          : 'Claim Airdrop'
      }
    </button>
  )
}

export default Claim