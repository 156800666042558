import { PropsWithChildren } from 'react'

interface Props {
  onClick?: () => void
}

function Button({ children, onClick }: PropsWithChildren<Props>) {
  return (
    <div onClick={onClick} className="common-button">
      {children} 
    </div>
  )
}


export default Button