import { FC, useEffect, useState } from 'react';
import { metamask, hooks } from '@/connectors/metamask';
import { NETWORK_CONFIG } from '@/constants/chain';
import type { MetaMaskInpageProvider } from '@metamask/providers';
import Button from './Button';

const { useChainId, useAccount } = hooks;

const WalletButton: FC = () => {
  const currentChainId = useChainId();
  const account = useAccount();
  const [addNetwork, setAddNetwork] = useState(false);

  useEffect(() => {
    if ((window as any).ethereum) {
      metamask.activate(NETWORK_CONFIG).catch((error) => {
        console.error('Failed to connect', error);
      });
    }
  }, [addNetwork]);

  const onConfirm = () => {
    if ((window as any).ethereum) {
      metamask.activate(NETWORK_CONFIG);
    } else {
      window.open(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      );
    }
  };

  const switchAndAddNetwork = async () => {
    const provider = (window as any).ethereum as MetaMaskInpageProvider;
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${NETWORK_CONFIG.chainId.toString(16)}` }],
      });
    } catch ({ data: switchError }: any) {
      const _error = switchError as { originalError: { code: number } };
      if (_error?.originalError?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...NETWORK_CONFIG,
                chainId: `0x${NETWORK_CONFIG.chainId.toString(16)}`,
              },
            ],
          });
          setAddNetwork(true);
        } catch (addError) {
          console.error('Failed to add network', addError);
        }
      }
    }
  };

  return (
    <>
      {!account ? (
        <Button onClick={() => onConfirm()}>CONNECT WALLET</Button>
      ) : (
        <>
          {currentChainId === NETWORK_CONFIG.chainId ? (
            <Button>{`${
              account.slice(0, 5) + '...' + account.slice(-5)
            }`}</Button>
          ) : (
            <Button onClick={() => switchAndAddNetwork()}>
              Switch to zkSync
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default WalletButton;
