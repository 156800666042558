import { useState, useMemo, useEffect, useCallback } from 'react'
import { Contract } from '@ethersproject/contracts'
import { BigNumber } from '@ethersproject/bignumber'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import queryString from 'query-string'
import axios from 'axios'
import { hooks as networkHooks } from '@/connectors/network'
import { hooks as metamaskHooks } from '@/connectors/metamask'
import AirDrop_ABI from '@/abis/Airdrop.json'
import { AIRDROP } from '@/constants/address'
import { SIGNER } from '@/constants/url'
import { Airdrop } from '@/typechain'
import { useBlockNumber } from '@/hooks/blockNumber'


const { useProvider } = networkHooks
const { useAccount, useProvider: useSignerProvider } = metamaskHooks
const ZERO = BigNumber.from(0)

export const useContract = () => {
  const provider = useProvider()

  return useMemo(
    () =>
      new Contract(AIRDROP, AirDrop_ABI, provider) as unknown as Airdrop,
    [provider]
  )
}

export const useCanClaimAmount = () => {
  const contract = useContract()
  const account = useAccount()
  const [canClaimAmount, setCanClaimAmount] = useState<BigNumber | null>(null)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (account) {
      contract
        .canClaimAmount(account)
        .then((res) => {
          setCanClaimAmount(res)
        })
        .catch((err) => {
          setCanClaimAmount(ZERO)
        })
    } else {
      setCanClaimAmount(ZERO)
    }
  }, [account, contract, blockNumber, setCanClaimAmount])

  return canClaimAmount
}

export const useStep = () => {
  const contract = useContract()
  const [step, setStep] = useState<number>(0)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    contract
      .getStep()
      .then((res) => setStep(res.toNumber()))
      .catch(() => setStep(0))
  }, [contract, blockNumber, setStep])

  return step
}

export const useClaimedCount = () => {
  const contract = useContract()
  const [claimedCount, setClaimedCount] = useState<BigNumber | null>(null)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    contract
      .claimedCount()
      .then((res) => setClaimedCount(res))
      .catch(() => setClaimedCount(ZERO))
  }, [contract, blockNumber, setClaimedCount])

  return claimedCount
}

export const useSignature = () => {
  const account = useAccount()
  const [signature, setSignature] = useState<string | null>(null)

  useEffect(() => {
    if (account) {
      axios.get(`/${account}`, { baseURL: SIGNER })
        .then((res) => {
          setSignature(res.data.signature)
        })
        .catch(() => setSignature(null))
    } else {
      setSignature(null)
    }
  }, [account, setSignature])

  return signature
}

export const useInfoView = () => {
  const contract = useContract()
  const account = useAccount()
  const blockNumber = useBlockNumber()
  const [infoView, setInfoView] = useState<
    {
      initClaim: BigNumber;
      currentClaim: BigNumber;
      claimed: boolean;
      claimedSupply: BigNumber;
      claimedCount: BigNumber;
      inviteUsers: BigNumber;
    } | null
  >(null)

  useEffect(() => {
    if (account) {
      contract
        .getInfoView(account)
        .then((res) => setInfoView(res))
        .catch(() => setInfoView(null))
    } else {
      setInfoView(null)
    }
  }, [contract, blockNumber, account, setInfoView])

  return infoView
}

export const useReferrer = () => {
  return useMemo(() => {
    if (!window.location.search) return AddressZero
    const { r } = queryString.parse(window.location.search)
    if (!r) return AddressZero
    try {
      return getAddress(r as string)
    } catch {
      return AddressZero
    }
  }, [])
}

export const useClaim = () => {
  const contract = useContract()
  const provider = useSignerProvider()
  const referrer = useReferrer()

  return useCallback(async (signature: string) => {
    if (signature && provider) {
      const data = contract.interface.encodeFunctionData('claim', [signature, referrer])
      const signer = provider.getSigner()
      const tx = await signer.sendTransaction({
        to: contract.address,
        value: '0x0',
        data,
      })

      await tx.wait();
    }
  }, [provider, contract, referrer])
}
