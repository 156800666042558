import { useEffect } from 'react'

interface Props {
  value: number
}

function Progress({ value }: Props) {
  useEffect(() => {
    const container = document.getElementById('progress') as any
    
    container.style.width = `${value}%`
  }, [value])

  return null
}

export default Progress
