import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

interface Props { 
  id: string
}

function Portal({ id, children }: PropsWithChildren<Props>) {
  return createPortal(
    children,
    document.getElementById(id) as Element
  )
}

export default Portal
